import React from 'react'
import "./Nav.css";
import cart from "../../assests/cart.svg";
import comment from "../../assests/envelope.svg";

const Nav = () => {
  return (
    <div className='nav'>
        <div className='nav-logo'>
                my shop
        </div>
        <div className='nav-right'>
            <div className='mail'>
                <input type="text" placeholder='your@email' />
                <button>subscribe</button>
            </div>
            <div className='account'>
            <i className='fas fa-user'></i>
                <span>account</span>
            </div>
            <div className='cart'>
            <i className='fas fa-shopping-cart'></i>
                <span>cart</span>
            </div>
        </div> 
    </div>
  )
}

export default Nav